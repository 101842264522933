export default [
  {
    key: 'startDate',
    label: 'field.fromDate',
    type: 'date',
    operator: '>',
    searchKey: 'created_at',
    suffix: '00:00:00',
    cols: 3,
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    type: 'date',
    searchKey: 'created_at',
    operator: '<',
    suffix: '23:59:59',
    cols: 3,
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    cols: 6,
    skip: true,
  },
]
