export default [
  // {
  //   key: "startDate",
  //   label: "field.fromDate",
  //   type: "date",
  //   searchKey: "created_at",
  //   suffix: "00:00:00",
  //   operator: "dt>=",
  //   cols: 3,
  // },
  // {
  //   key: "toDate",
  //   label: "field.toDate",
  //   type: "date",
  //   searchKey: "created_at",
  //   suffix: "23:59:59",
  //   operator: "dt<=",
  //   cols: 3,
  // },
  // {
  //   key: "period",
  //   label: "field.period",
  //   type: "radio",
  //   options: [
  //     { text: "field.today", value: "today" },
  //     { text: "field.yesterday", value: "yesterday" },
  //     { text: "field.thisWeek", value: "thisWeek" },
  //     { text: "field.lastWeek", value: "lastWeek" },
  //     { text: "field.thisMonth", value: "thisMonth" },
  //     { text: "field.lastMonth", value: "lastMonth" },
  //   ],
  //   cols: 6,
  //   skip: true,
  // },
];
