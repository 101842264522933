export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'ipaddress',
    label: 'field.ipAddress',
  },
  {
    key: 'loginAt',
    label: 'field.date',
  },
  {
    key: 'deviceType',
    label: 'field.deviceType',
  },
  {
    key: 'useragent',
    label: 'field.device',
    tdClass: 'device'
  },
];
