<template>
  <div>
    <b-modal
      id="modal-bet-history"
      hide-footer
      centered
      :title="title"
      size="xl"
      v-model="isShow"
    >
      <!-- Search Container Card -->
      <n-search-container
        :active="true"
        :show-header="false"
        @search="search"
        @reset="reset"
        v-if="searchFields.length"
        :loading="loading"
      >
        <n-search-input ref="search" :fields="searchFields" v-model="params">
          <template #period="item">
            <b-form-group
              :label-for="item.field.key"
              :label="$t(item.field.label)"
            >
              <b-form-radio-group
                :ref="item.field.key"
                v-if="item.field.type === 'radio'"
                :id="item.field.key"
                v-model="params[item.field.key]"
                buttons
                button-variant="outline-primary rounded-0"
                class="flex-wrap"
              >
                <b-form-radio
                  :value="option.value"
                  v-for="option in item.field.options"
                  :key="option.value"
                  >{{ $t(option.text) }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </template>
        </n-search-input>
      </n-search-container>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-0-5">
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
          >
            <template #cell(loginAt)="data">
              {{ data.item.loginAt | formatDate }}
            </template>
          </n-table>
          <n-pagination
            ref="pagination"
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
          ></n-pagination>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
  BModal,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const SeniorRepository = Repository.get("senior");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    BModal,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
  },
  watch: {
    perPage(value) {
      this.list();
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      isShow: false,
      loading: false,
      title: "",
      items: [],
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: 1,
        order: "",
        sort: "",
        userId: "",
        startDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        period: "today",
      },
    };
  },
  methods: {
    show(item) {
      this.title = item.username;
      this.params = {
        page: 1,
        order: "",
        sort: "",
        userId: item.id,
        startDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        period: "today",
      };
      this.isShow = true;
      this.list();
    },
    hide() {
      this.isShow = false;
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.params.search = searchText;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.$refs.pagination.reset();
      this.getData();
    },
    getData() {
      this.loading = true;
      SeniorRepository.listIp({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
